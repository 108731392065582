import PropTypes from "prop-types"
import React, { useState } from "react"
import CountUp from "react-countup"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import VisibilitySensor from "react-visibility-sensor"
import Layout from "../components/layout"
import SliderOneAlpha from "../images/Alpha.png"
import DesignRightRoundOne from "../images/DesignRightRoundOne.png"
import DesignRightRoundTwo from "../images/DesignRightRoundTwo.png"
import SliderthreeleafGuard from "../images/Leafguard.png"
import SlidertwoNSS from "../images/NewStory.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import Shapefour from "../images/bannerElement/white-design-element-webdesign.png"
import AIAssistedCoding from "../images/icons/WebDesignDevelopment/AI-Assisted-Coding.svg"
import Acceleratedemandgeneration from "../images/icons/WebDesignDevelopment/Accelerate-demand-generation.svg"
import AtractInform from "../images/icons/WebDesignDevelopment/Attract-inform-visitors.svg"
import CustomerJourney from "../images/icons/WebDesignDevelopment/CustomerJourney.svg"
import Define from "../images/icons/WebDesignDevelopment/Define.svg"
import Design from "../images/icons/WebDesignDevelopment/Design.svg"
import Develop from "../images/icons/WebDesignDevelopment/Develop.svg"
import Discover from "../images/icons/WebDesignDevelopment/Discover.svg"
import Guideusersthroughintuitivejourneys from "../images/icons/WebDesignDevelopment/Guide-users-through-intuitive-journeys.svg"
import HeatmapAnalysis from "../images/icons/WebDesignDevelopment/HeatmapAnalysis.svg"
import PersonaCreation from "../images/icons/WebDesignDevelopment/PersonaCreation.svg"
import Turnvisitorsintocustomers from "../images/icons/WebDesignDevelopment/Turn-visitors-into-customers.svg"
import image1 from "../images/image-one.svg"
import image3 from "../images/ma-image.svg"

import Quest from "../images/logos/Web Des & Dev/Group3.svg"
import CrossLink from "../images/logos/Web Des & Dev/Group4.svg"
import GD from "../images/logos/Web Des & Dev/Group5.svg"
import Englert from "../images/logos/Web Des & Dev/Group6.svg"
import Proviniti from "../images/logos/Web Des & Dev/Group7.svg"
import Alpha from "../images/logos/Web Des & Dev/Group9.svg"
import platforms from "../images/platforms-and-partners.svg"
import risesmart from "../images/risesmart.png"
import "../styles/404.css"

const WebDesignAndDevelopment = () => {
  const [countUpVisible, setCountUpVisible] = useState(false)
  const handleVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUpVisible(true)
    }
  }
  const settings = {
    dots: true,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 0,
    pauseOnHover: false,
    loop: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    appendDots: dots => (
      <ul style={{ display: "flex", gap: "20px" }}> {dots} </ul>
    ),

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Web&Apps | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="web-design-and-development content-creative">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>
                      Strategic web design <br />
                      that converts
                    </h1>
                    <p>Elevate your online presence with stunning designs</p>
                    <a className="button" href="/contact-us">
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>Turn your visitors into lifelong brand advocates</h2>
              <p>
                We build cutting-edge websites that captivate users and deliver
                results for brands. Our award-winning team leverages the latest
                web technologies and design principles to create digital
                experiences that inform, guide, and convert.
              </p>
            </div>
          </section>
          <section id="ImageDescColSection">
            <div class="container">
              <h2>
                We know how to craft paths to customer acquisition. Here's how
                we do it:
              </h2>
              <div class="imgdesc-cardwrapper">
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Attract & inform visitors"
                      width="100"
                      height="100"
                      src={AtractInform}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Attract & Inform Visitors</p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Tech Expert"
                      width="100"
                      height="100"
                      src={Guideusersthroughintuitivejourneys}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Guide Users through Intuitive Journeys</p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Ahead of Curve"
                      width="100"
                      height="100"
                      src={Turnvisitorsintocustomers}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Turn Visitors into Customers</p>
                  </div>
                </div>
                <div class="image-desc-card">
                  <div class="img-wrap">
                    <img
                      alt="Peace of Mind"
                      width="100"
                      height="100"
                      src={Acceleratedemandgeneration}
                    />
                  </div>
                  <div class="text-wrap">
                    <p>Accelerate Demand Generation</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="TextImageSection2" class="section-greybg">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>From concept to complete prototype</h2>
                  <p>
                    We follow a user-centric approach to create stunning and
                    intuitive website designs. Our focus is to make you
                    experience the website's navigation, animations, and
                    features before development begins.
                  </p>
                  <p class="point-title">Our design process ensures:</p>
                  <ul className="bullet-point">
                    <li>User-centric approach</li>
                    <li>Wireframes and mockups</li>
                    <li>Interactive designs and complete prototypes</li>
                    <li>Focus on functionality and user engagement</li>
                    <li>Seamless user experience across devices</li>
                  </ul>
                </div>
                <div class="img-wrap">
                  <img alt="Design and Development" src={DesignRightRoundOne} />
                </div>
              </div>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>
                    Future-proof web design, <br />
                    built to last
                  </h2>
                  <p>
                    With over a decade of experience, our team keeps ahead of
                    web and mobile innovations. We craft solutions using the
                    latest standards and platforms to create memorable
                    experiences across devices.
                  </p>
                  <p class="point-title">Our development process ensures</p>
                  <ul>
                    <li>
                      <span>Code Security: </span>Rigorous reviews close
                      vulnerabilities
                    </li>
                    <li>
                      <span>Code Simplicity: </span>Clean, understandable code
                      for maintainability
                    </li>
                    <li>
                      <span>Scalability: </span>Built to adapt as your business
                      grows
                    </li>
                  </ul>
                </div>
                <div class="img-wrap">
                  <img alt="Copy write" src={DesignRightRoundTwo} />
                </div>
              </div>
            </div>
          </section>
          <section id="IconColSection">
            <div class="container">
              <div class="inner-wrapper">
                <h2>How we leverage AI to power up your website</h2>
                <div class="icon-cardwrapper">
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img alt="Unmatched Uptime" src={PersonaCreation} />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <strong>
                          Persona Creation
                          <br />
                        </strong>
                        We use AI to craft detailed personas that inform
                        user-centric design decisions.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img alt="Smart SEO Insights" src={CustomerJourney} />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <strong>
                          Customer Journey <br />
                        </strong>
                        AI helps us map optimal customer journeys to deliver
                        engaging user experiences.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Future-Proof Technology"
                        src={HeatmapAnalysis}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <strong>
                          Heatmap Analysis <br />
                        </strong>
                        Our AI-generated heatmaps make data-driven design
                        decisions that optimize user engagement.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img alt="Intuitive Search" src={AIAssistedCoding} />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <strong>
                          AI-assisted Coding
                          <br />
                        </strong>
                        The development process with AI-assisted coding is
                        faster and has efficient website builds.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="BgImgDescSection">
            <div class="container">
              <h2>Our 4D design process</h2>
              <div class="BgImgDesc-wrapper">
                <div class="each-card">
                  <div class="img-wrap">
                    <span class="imgcover">
                      <img src={image1} alt="Image one" />
                    </span>
                    <div class="image-holder">
                      <img
                        alt="AI image"
                        src={Discover}
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                  <div class="text-wrap">
                    <h5>Discover</h5>
                    <ul className="bullet-point">
                      <li>Stakeholder &amp; End User Interviews</li>
                      <li>Competitive &amp; Content Audit</li>
                      <li>Design Strategy</li>
                    </ul>
                  </div>
                </div>
                <div class="each-card">
                  <div class="img-wrap">
                    <span class="imgcover">
                      <img src={image1} alt="Image two" />
                    </span>
                    <div class="image-holder">
                      <img
                        alt="Proactive"
                        src={Define}
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                  <div class="text-wrap">
                    <h5>Define</h5>
                    <ul className="bullet-point">
                      <li>Heuristic Analysis</li>
                      <li>Personas &amp; User Flows</li>
                      <li>Site Maps</li>
                      <li>Interactive Wireframes</li>
                    </ul>
                  </div>
                </div>
                <div class="each-card">
                  <div class="img-wrap">
                    <span class="imgcover">
                      <img src={image1} alt="Image two" />
                    </span>
                    <div class="image-holder">
                      <img
                        alt="Tech Expert"
                        src={Design}
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                  <div class="text-wrap">
                    <h5>Design</h5>
                    <ul className="bullet-point">
                      <li>Interactive Visual Design</li>
                      <li>Hi-fidelity Prototypes</li>
                      <li>Style Guide</li>
                    </ul>
                  </div>
                </div>
                <div class="each-card">
                  <div class="img-wrap">
                    <span class="imgcover">
                      <img
                        src={image3}
                        alt="Image three"
                        width="164"
                        height="164"
                      />
                    </span>
                    <div class="image-holder">
                      <img
                        alt="Peace of Mind"
                        src={Develop}
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                  <div class="text-wrap">
                    <h5>Develop</h5>
                    <ul className="bullet-point">
                      <li>Website Architecture</li>
                      <li>Template Creation</li>
                      <li>Content Migration</li>
                      <li>Testing</li>
                      <li>Launch</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="CaseStudy">
            <div class="sliderwrap">
              <div class="contentholder">
                <div class="container">
                  <div class="descp">
                    <p class="title">Case Study</p>
                    <div class="img-wrap">
                      <img src={risesmart} alt="RiseSmart Logo" />
                    </div>
                    <div>
                      <h2>New site boosted web traffic by 150%</h2>
                    </div>
                    <div>
                      <p>
                        The previous website had performance issues, and the
                        user interface and user experience needed improvement.
                        RiseSmart aimed to tackle these issues head-on.
                        Additionally, they strived to boost customer
                        interaction, ensuring the platform was powerful in terms
                        of SEO, easy to maintain, and scalable for future
                        expansion.
                      </p>
                    </div>
                    <a
                      className="button"
                      href="https://www.position2.com/position2-risesmart-case-study.pdf"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageSectionIntro">
            <div class="container">
              <h2>Our recent work</h2>
              <p>
                We cover a wide range of designs and functionalities: from
                complex technology-based websites to focused B2B and engaging
                B2C - we create it all. And win awards too.
              </p>
            </div>
          </section>
          <section id="ImgContentSlider">
            <div class="container">
              <div className="slider-wrapper">
                <Slider {...settings} className="inner-slider">
                  <div className="each-slider">
                    <div className="text-image-holder">
                      <div className="text-wrap">
                        <h4>Alpha</h4>
                        <h3>Laying the foundation for next-gen founders</h3>
                        <p>
                          Created a new home for an invite-only networking
                          community for founders, CEOs, and leading technology
                          influencers. We ensured that the site reflected their
                          panache and ethos.
                        </p>
                        <ul className="countUp">
                          <li>
                            <VisibilitySensor onChange={handleVisibilityChange}>
                              <div className="count-wrap">
                                <CountUp
                                  end={countUpVisible ? 55 : 0}
                                  duration={3}
                                />
                                <span className="plus">%</span>
                              </div>
                            </VisibilitySensor>
                            Increase in visits
                          </li>
                          <li>
                            <VisibilitySensor onChange={handleVisibilityChange}>
                              <div className="count-wrap">
                                <CountUp
                                  end={countUpVisible ? 32 : 0}
                                  duration={3}
                                />
                                <span className="plus">%</span>
                              </div>
                            </VisibilitySensor>
                            Decrease in bounce rate
                          </li>
                        </ul>
                        <a
                          className="button solutionvideo"
                          href="https://www.alpha.network/"
                          target="_blank"
                        >
                          Visit Website
                        </a>
                      </div>
                      <div className="img-wrap">
                        <img
                          width={500}
                          height={500}
                          src={SliderOneAlpha}
                          alt="Alpha"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="each-slider">
                    <div className="text-image-holder">
                      <div className="text-wrap">
                        <h4>New Story Schools</h4>
                        <h3>
                          Revamped the online presence of special education
                          innovators
                        </h3>
                        <p>
                          Enhanced the search functionality and the design which
                          helped the students with serious and complex
                          behavioural and educational challenges.
                        </p>
                        <ul className="countUp">
                          <li>
                            <VisibilitySensor onChange={handleVisibilityChange}>
                              <div className="count-wrap">
                                <CountUp
                                  end={countUpVisible ? 43 : 0}
                                  duration={3}
                                />
                                <span className="plus">%</span>
                              </div>
                            </VisibilitySensor>
                            Improvement in overall traffic
                          </li>
                          <li>
                            <VisibilitySensor onChange={handleVisibilityChange}>
                              <div className="count-wrap">
                                <CountUp
                                  end={countUpVisible ? 72 : 0}
                                  duration={3}
                                />
                                <span className="plus">%</span>
                              </div>
                            </VisibilitySensor>
                            Increase in the overall avg. session duration
                          </li>
                          <li>
                            <VisibilitySensor onChange={handleVisibilityChange}>
                              <div className="count-wrap">
                                <CountUp
                                  end={countUpVisible ? 36 : 0}
                                  duration={3}
                                />
                                <span className="plus">%</span>
                              </div>
                            </VisibilitySensor>
                            Decrease in bounce rate
                          </li>
                        </ul>
                        <a
                          className="button solutionvideo"
                          href="https://newstoryschools.com/"
                          target="_blank"
                        >
                          Visit Website
                        </a>
                      </div>
                      <div className="img-wrap">
                        <img
                          width={500}
                          height={500}
                          src={SlidertwoNSS}
                          alt="NSS"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="each-slider">
                    <div className="text-image-holder">
                      <div className="text-wrap">
                        <h4>LeafGuard</h4>
                        <h3>
                          An efficient online presence for a seamless experience
                        </h3>
                        <p>
                          LeafGuard is a leading manufacturer of gutter systems
                          that never clog. We recreated their simple-to-use
                          clog-free experience, online.
                        </p>
                        <ul className="countUp">
                          <li>
                            <VisibilitySensor onChange={handleVisibilityChange}>
                              <div className="count-wrap">
                                <CountUp
                                  end={countUpVisible ? 3 : 0}
                                  duration={3}
                                />
                                <span className="plus">min</span>
                              </div>
                            </VisibilitySensor>
                            Avg time spent increased
                          </li>
                          <li>
                            <VisibilitySensor onChange={handleVisibilityChange}>
                              <div className="count-wrap">
                                <CountUp
                                  end={countUpVisible ? 31 : 0}
                                  duration={3}
                                />
                                <span className="plus">%</span>
                              </div>
                            </VisibilitySensor>
                            Decrease in bounce rate
                          </li>
                        </ul>
                        <a
                          className="button solutionvideo"
                          href="https://www.leafguard.com/"
                          target="_blank"
                        >
                          Visit Website
                        </a>
                      </div>
                      <div className="img-wrap">
                        <img
                          width={500}
                          height={500}
                          src={SliderthreeleafGuard}
                          alt="Alpha"
                        />
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section>
          <section id="Parallax" class="section-greybg">
            <div class="container">
              <div class="TextImgSection">
                <div class="text-image-holder">
                  <div class="text-wrap">
                    <div class="animation-text">
                      <div>
                        <h3>Platforms and partners</h3>
                      </div>
                      <div>
                        <p>
                          We work with industry-leading technology platforms and
                          partners to give your business a leg up in digital
                          marketing.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-img">
                    <img
                      alt="Platforms"
                      width="550"
                      height="550"
                      src={platforms}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PartnerLogoSection">
            <div class="container">
              <div class="logo-wrap">
                <div class="each-logo">
                  <img src={Quest} alt="Quest" />
                </div>
                <div class="each-logo">
                  <img src={CrossLink} alt="CrossLink" />
                </div>
                <div class="each-logo">
                  <img src={GD} alt="Coveo" />
                </div>
                <div class="each-logo">
                  <img src={Englert} alt="Englert" />
                </div>
                <div class="each-logo">
                  <img src={Proviniti} alt="Provinity" />
                </div>
                <div class="each-logo">
                  <img src={Alpha} alt="Alpha" />
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Ignite your brand's growth with a powerful website today!</h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

WebDesignAndDevelopment.propTypes = {
  siteTitle: PropTypes.string,
}

WebDesignAndDevelopment.defaultProps = {
  siteTitle: ``,
}

export default WebDesignAndDevelopment
